import React from 'react'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import json from 'react-syntax-highlighter/dist/cjs/languages/hljs/json'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { trim } from 'lodash'
import parseJson from 'parse-json'

SyntaxHighlighter.registerLanguage('json', json)

export default React.memo(({ fields, isList = false }) => {
  const composedFields = fields.reduce((acc, field) => {
    const {
      name,
      sampleValue,
      type: { name: typeName },
    } = field
    let fieldType = typeName.toLowerCase()
    const valueIsObject = /\{|\[/.test(sampleValue)

    let parsedValue = null
    switch (fieldType) {
      case 'integer':
        parsedValue = +sampleValue
        break
      case 'unix timestamp':
        parsedValue = +sampleValue
        break
      case 'boolean':
        parsedValue = sampleValue === 'true'
        break
      default:
        parsedValue = trim(sampleValue)
    }

    if (valueIsObject) {
      parsedValue = parseJson(sampleValue)
    }

    return {
      ...acc,
      [name]: parsedValue,
    }
  }, {})

  const codeSample = JSON.stringify(
    isList ? [composedFields] : composedFields,
    null,
    2
  )
  return (
    <SyntaxHighlighter
      style={monokaiSublime}
      customStyle={{
        marginTop: '2rem',
        borderRadius: '4px',
        padding: '0',
        whiteSpace: 'pre-wrap',
      }}
      language="json"
    >
      {codeSample}
    </SyntaxHighlighter>
  )
})
