import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import Docs from '../components/docs'

const MainSection = styled.main`
  margin-left: 400px;
  @media print {
    display: block;
  }
`

const IndexPage = ({ data }) => {
  // Filter out ContentfulApiLink sections so there's no attempt at rendering them in the main docs section.
  // Checks against the typename of the first block of content for the section, as the section
  // itself doesn't expose a typename we can filter against, and filtering by section name isn't very resilient.
  const sections = data.apiDocs.edges[0].node.sections.filter(section => {
    return section.content[0].__typename !== 'ContentfulApiLink'
  })

  return (
    <MainSection>
      <Docs sections={sections} />
    </MainSection>
  )
}

export const query = graphql`
  query IndexPageQuery {
    site: site {
      siteMetadata {
        title
        description
      }
    }
    apiDocs: allContentfulApiDocs {
      edges {
        node {
          ...DocsFragment
        }
      }
    }
  }
`

export default IndexPage
