import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import Search from './search'
import DocsSection from './docs-section'

const Documentation = styled.section`
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5rem;
  @media print {
    height: auto;
    padding: 1rem;
    overflow: visible;
  }
`

export default ({ sections }) => (
  <>
    <Search />
    <Documentation className="docs">
      {sections.map(section => (
        <DocsSection section={section} key={section.id} />
      ))}
    </Documentation>
  </>
)

export const query = graphql`
  fragment DocsFragment on ContentfulApiDocs {
    sections {
      ...DocsSectionFragment
    }
  }
`
