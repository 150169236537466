import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { has, get, sortBy, differenceBy } from 'lodash'

export default (WrappedComponent, path) => {
  const data = useStaticQuery(graphql`
    query SupportBadgeQuery {
      implementations: allContentfulIntegrationImplementation {
        edges {
          node {
            title
            pmsKey
          }
        }
      }
      allImplementationSupport {
        edges {
          node {
            id
            title
            pmsKey
            description
            internal {
              content
            }
          }
        }
      }
    }
  `)

  const implementations = data.implementations.edges.map(({ node }) => node)
  const implementationSupport = data.allImplementationSupport.edges.map(
    ({ node }) => {
      const { title, pmsKey, info } = node
      const { apiSupport } = JSON.parse(node.internal.content)
      return {
        id: node.id,
        title,
        pmsKey,
        info,
        ...apiSupport,
      }
    }
  )

  const supportedImplementations = implementationSupport
    .filter(implementation => has(implementation, path))
    .map(implementation => {
      const { title, pmsKey } = implementation
      const info = get(implementation, `${path}.info`)
      return {
        title,
        pmsKey,
        info,
      }
    })

  const unsupportedImplementations = differenceBy(
    implementations,
    supportedImplementations,
    'pmsKey'
  )
  const supportStatus = {
    supported: supportedImplementations.length
      ? sortBy(supportedImplementations, ['title'])
      : null,
    unsupported: unsupportedImplementations.length
      ? sortBy(unsupportedImplementations, ['title'])
      : null,
  }

  let supportLevel = 'some'
  if (!supportStatus.supported) {
    supportLevel = 'future'
  } else if (!supportStatus.unsupported) {
    supportLevel = 'all'
  }

  return (
    <WrappedComponent
      supportLevel={supportLevel}
      supportStatus={supportStatus}
      path={path}
    />
  )
}
