import React from 'react'
import { connectStateResults, Index } from 'react-instantsearch-dom'
import { css } from '@emotion/core'
import Hits from './search-hits'

const RenderSearchResults = ({ searchState, clickHandler }) => {
  const hasQuery = searchState && searchState.query
  return hasQuery ? (
    <div
      role="link"
      tabIndex="0"
      css={css`
        border-top: 1px solid #dee7ee;
        max-height: 50vh;
        overflow: scroll;
        grid-column: 1 / -1;
      `}
      onMouseDown={() => {
        clickHandler()
      }}
    >
      <Index indexName="prod_apiGuides">
        <Hits forSubject="Guides" />
      </Index>
      <Index indexName="prod_apiResources">
        <Hits forSubject="Resources" />
      </Index>
    </div>
  ) : null
}

const CustomSearchResults = connectStateResults(RenderSearchResults)

export default CustomSearchResults
