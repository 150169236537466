import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Badge = styled.div`
  display: inline-block;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 12px;
  min-width: 10rem;
  text-align: center;
`

const base = css`
  background: #c0c7d4;
`

export default ({ children, styling = null }) => (
  <div className="badge-wrap">
    <Badge
      css={css`
        ${base}
        ${styling}
      `}
    >
      {children}
    </Badge>
  </div>
)
