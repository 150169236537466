import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import DocsItem from './docs-item'

const DocSection = styled.div`
  &:first-of-type {
    padding-top: 8rem;
  }
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-gap: 0.5rem;
  align-items: right;
  @media print {
    display: block;
  }
`

const DocSectionHeader = styled.h5`
  color: #666;
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
  justify-self: end;
  line-height: 2;
  @media print {
    display: none;
  }
`

export default React.memo(({ section }) => {
  return (
    <DocSection className="docs-section">
      <DocSectionHeader className="docs-section__header">
        {section.name}
      </DocSectionHeader>
      <section className="docs-section__items">
        {section.content.map(content => (
          <DocsItem content={content} key={content.id} />
        ))}
      </section>
    </DocSection>
  )
})

export const query = graphql`
  fragment DocsSectionFragment on ContentfulApiDocsSection {
    id
    name
    content {
      __typename
      ... on ContentfulApiGuide {
        ...DocsGuideFragment
      }
      ... on ContentfulApiResource {
        ...DocsResourceFragment
      }
    }
  }
`
