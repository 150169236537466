import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Badge from './badge'
import CheckMark from './checkmark.svg'
import CrossMark from './crossmark.svg'

const STYLES = {
  all: css`
    background: #0fc98c;
  `,
  some: css`
    background: #ffac02;
  `,
  future: css`
    background: #9fa2b5;
  `,
}

const SupportListWrap = styled.div`
  position: absolute;
  top: 100%;
  width: 290px;
  right: 50%;
  transform: translate(50%);
  margin: 0;
  z-index: 999;
`

const SupportList = styled.ul`
  list-style: none;
  background: transparent;
  border-radius: 4px;
  margin: 0;
  margin-bottom: 1rem;
  padding: 1rem;
`

const SupportListItem = styled.li`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: #fff;
  align-items: center;
  margin: 0;
  &:last-of-type {
    border-bottom: 0;
  }
  .support-badge-text-wrap {
    flex-basis: 80%;
    display: flex;
    flex-direction: column;
    > p {
      margin: 0;
    }
  }
  > img {
    margin: 0;
    margin-right: 10px;
  }
  & a {
    color: inherit;
    text-decoration: underline;
  }
`

export default React.memo(({ supportLevel, supportStatus, path }) => {
  const { supported, unsupported } = supportStatus
  const [supportListVisible, toggleSupportList] = useState(false)
  return (
    <div
      role="tooltip"
      className="support-info__wrap"
      css={css`
        display: inline-block;
        position: relative;
      `}
      onMouseEnter={() => toggleSupportList(true)}
      onMouseLeave={() => toggleSupportList(false)}
    >
      {supportListVisible ? (
        <SupportListWrap>
          <SupportList
            css={css`
              ${STYLES[supportLevel]}
            `}
          >
            {supported
              ? supported.map(({ title, pmsKey, info }) => {
                  return (
                    <SupportListItem key={pmsKey}>
                      <img src={CheckMark} alt="Supported" />
                      <span className="support-badge-text-wrap">
                        <p>{title}</p>
                        {info ? <small>{info}</small> : null}
                      </span>
                    </SupportListItem>
                  )
                })
              : null}
            {unsupported
              ? unsupported.map(({ title, pmsKey, info }) => {
                  return (
                    <SupportListItem key={pmsKey}>
                      <img src={CrossMark} alt="Unsupported" />
                      <p>
                        {title}
                        {info ? <small>{info}</small> : null}
                      </p>
                    </SupportListItem>
                  )
                })
              : null}
          </SupportList>
        </SupportListWrap>
      ) : null}
      <Badge
        styling={css`
          color: #fff;
          text-transform: uppercase;
          font-weight: 600;
          cursor: help;
          ${STYLES[supportLevel]}
        `}
      >
        {`${supportLevel} integrations`.toUpperCase()}
      </Badge>
    </div>
  )
})
