import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

import { Table, TableRow } from '../shared/param-field-table'
import ResourceExample from './resource-example'
import { useTable, useFlexLayout } from 'react-table'

const ResourceResponseHeader = styled.h3`
  margin: 1rem 0;
`

export default React.memo(({ response }) => {
  const tableColumns = React.useMemo(
    () => [
      {
        id: 'returnField',
        Header: 'Field',
        accessor: 'field',
      },
      {
        id: 'returnType',
        Header: 'Type',
        Cell: ({ row, cell }) => {
          return <Link to={`#${row.id}`}>{cell.value}</Link>
        },
        accessor: 'type',
      },
    ],
    []
  )
  const { name, singularName, responseIsAList, uniqueIdentifier } = response
  const data = [
    {
      field: 'data',
      type: responseIsAList ? `Array of ${name}` : singularName,
      uniqueIdentifier,
    },
  ]
  const tableData = React.useMemo(() => data, [data])
  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: tableData,
      getRowId: row => row.uniqueIdentifier,
    },
    useFlexLayout
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <>
      <ResourceResponseHeader>Returns</ResourceResponseHeader>
      <Table {...getTableProps()}>
        <div className="table__head">
          {headerGroups.map(headerGroup => (
            <div
              className="table-header__row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map(column => (
                <div className="table-head__cell" {...column.getHeaderProps()}>
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="table__body" {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableRow className="table-body__row" {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <div className="table-body__cell" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </div>
                  )
                })}
              </TableRow>
            )
          })}
        </div>
      </Table>
      {response.fields ? (
        <ResourceExample fields={response.fields} isList={response.isList} />
      ) : null}
    </>
  )
})

export const query = graphql`
  fragment ResourceResponseFragment on ContentfulApiAction {
    responseIsAList
    responseData {
      id
      name
      singularName
      uniqueIdentifier
      fields: contentfulfields {
        id
        name
        uniqueIdentifier
        sampleValue
        type {
          id
          name
        }
      }
    }
  }
`
