import React from 'react'
import { navigate, graphql } from 'gatsby'
import styled from '@emotion/styled'

import ResourceActionItem from './resource/resource-action-item'
import ResourceExample from './resource/resource-example'
import ParamFieldTable from './shared/param-field-table'

import PermaLinkIconImg from './perma-link-icon.png'

import WithImplementationSupport from './shared/with-implementation-support'
import SupportBadge from './shared/support-badge'

export const DocsItem = styled.div`
  margin-bottom: 4rem;
`

export const DocsItemHeader = styled.h1`
  display: grid;
  grid-template-columns: auto 1fr;
  &:hover > .perma-link-icon {
    display: block;
  }
`

const SubSectionHeader = DocsItemHeader.withComponent('h2')

export const PermaLinkIcon = styled.img`
  cursor: pointer;
  display: none;
  width: 20px;
  margin: 0;
  margin-left: 1rem;
  padding: 0;
  align-self: center;
`

export default React.memo(
  ({
    content: {
      name,
      uniqueIdentifier,
      internal,
      body,
      subSections,
      fields,
      api_action,
    },
  }) => (
    <DocsItem id={uniqueIdentifier} className="docs-item">
      <DocsItemHeader className="docs-item__header">
        <span
          dangerouslySetInnerHTML={{
            __html: name.replace(
              /`(\S+)`/g,
              (full, match) => `<pre>${match}</pre>`
            ),
          }}
        />
        <PermaLinkIcon
          className="perma-link-icon"
          src={PermaLinkIconImg}
          onClick={event => navigate(`/#${uniqueIdentifier}`)}
        />
      </DocsItemHeader>
      {internal.type === 'ContentfulApiResource'
        ? WithImplementationSupport(
            SupportBadge,
            `resources.${uniqueIdentifier}`
          )
        : null}

      {body ? (
        <section
          className="docs-item__body"
          dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
        />
      ) : null}

      {subSections ? (
        <section className="docs-item__subsections">
          {subSections.map(({ name, id, uniqueIdentifier, body }) => (
            <div key={id} className="subsection">
              <SubSectionHeader className="subsection__header">
                <span
                  id={uniqueIdentifier}
                  dangerouslySetInnerHTML={{
                    __html: name.replace(
                      /`(\S+)`/g,
                      (full, match) => `<pre>${match}</pre>`
                    ),
                  }}
                />
                <PermaLinkIcon
                  className="perma-link-icon"
                  src={PermaLinkIconImg}
                  onClick={event => navigate(`/#${uniqueIdentifier}`)}
                />
              </SubSectionHeader>
              <section
                className="subsection__body"
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
                tag="section"
              />
            </div>
          ))}
        </section>
      ) : null}

      {fields ? (
        <>
          <ParamFieldTable
            colHeader="Field"
            fields={fields}
            resource={uniqueIdentifier}
            action={null}
          />
          <ResourceExample fields={fields} />
        </>
      ) : null}

      {api_action
        ? api_action.map(action => (
            <ResourceActionItem action={action} key={action.id} />
          ))
        : null}
    </DocsItem>
  )
)

export const query = graphql`
  fragment DocsResourceFragment on ContentfulApiResource {
    id
    name
    uniqueIdentifier
    internal {
      type
    }
    body: explanation {
      childMarkdownRemark {
        html
      }
    }
    api_action {
      ...ResourceActionFragment
      ...ResourceResponseFragment
    }
    fields: contentfulfields {
      ...ResourceFieldFragment
    }
  }

  fragment DocsGuideFragment on ContentfulApiGuide {
    id
    name
    uniqueIdentifier
    internal {
      type
    }
    body {
      childMarkdownRemark {
        html
      }
    }
    subSections: sections {
      ... on ContentfulApiGuide {
        id
        name
        uniqueIdentifier
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
