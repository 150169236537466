import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { css } from '@emotion/core'

import SearchIcon from './search-icon.svg'
import ClearSearch from './clear-search.svg'

const searchInput = css`
  background-image: url(${SearchIcon});
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  border-radius: 50%;
  height: 54px;
  width: 54px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: width 0.2s ease;
  &.active {
    border: 0;
    outline: none;
    border-radius: 25px;
    background-position: left;
    margin-left: 1rem;
    padding-left: 2rem;
    width: 30rem;
    cursor: text;
  }
`

const clearSearchButton = css`
  align-self: center;
  border: 0;
  align-self: center;
  cursor: pointer;
  margin: 0;
  margin-right: 1rem;
  height: 12px;
  width: 12px;
  display: none;
  &.active {
    display: block;
  }
`

const RenderSearchBox = ({ searchState, setSearchState, resetSearchState }) => (
  <>
    <input
      css={searchInput}
      type="search"
      placeholder=""
      value={searchState().query}
      onFocus={event => {
        event.currentTarget.classList.add('active')
        event.currentTarget.placeholder = 'Search'
        event.currentTarget.nextElementSibling.classList.add('active')
      }}
      onChange={event => {
        setSearchState({
          query: event.currentTarget.value,
        })
      }}
      onBlur={event => {
        event.currentTarget.classList.remove('active')
        event.currentTarget.placeholder = ''
        event.currentTarget.nextElementSibling.classList.remove('active')
        resetSearchState()
      }}
    />
    <img src={ClearSearch} css={clearSearchButton} alt="" />
  </>
)

const CustomSearchBox = connectSearchBox(RenderSearchBox)

export default CustomSearchBox
