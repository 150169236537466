import React from 'react'
import { navigate } from 'gatsby'
import { connectHits } from 'react-instantsearch-dom'
import { css } from '@emotion/core'

const searchHit = css`
  margin: 0;
  &:hover {
    background: #2971f4;
  }
`

const resultsHeader = css`
  color: rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  margin: 1rem;
  font-weight: 400;
`

const hitView = css`
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  justify-self: right;
  font-size: 13px;
`

const hitLink = css`
  cursor: pointer;
  display: grid;
  grid-template-columns: 2fr 1fr;
  color: #000;
  padding: 1rem;
  &:hover {
    color: #fff;
  }
`

const RenderHits = ({ hits, forSubject }) => (
  <>
    <h5 css={resultsHeader}>
      {hits.length ? forSubject : `No results for ${forSubject}`}
    </h5>
    <ul
      css={css`
        margin-left: 0;
        list-style: none;
      `}
    >
      {hits.map(hit => (
        <li key={hit.objectID} css={searchHit}>
          <span
            role="link"
            tabIndex="0"
            css={hitLink}
            onMouseDown={event => {
              navigate(`#${hit.uniqueIdentifier}`, {
                replace: true,
              })
            }}
          >
            <span>{hit.name}</span>
            <span css={hitView}>View →</span>
          </span>
        </li>
      ))}
    </ul>
  </>
)

const CustomHits = connectHits(RenderHits)

export default CustomHits
