import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import ParamFieldTable from '../shared/param-field-table'
import ResponseTable from './response-table'
import Badge from '../shared/badge'

import WithImplementationSupport from '../shared/with-implementation-support'
import SupportBadge from '../shared/support-badge'

const ResourceActionItem = styled.div`
  margin: 4rem 0;
`

export default React.memo(
  ({
    action: {
      name,
      uniqueIdentifier,
      httpMethod,
      uri,
      pathParameters,
      bodyParameters,
      queryParameters,
      responseData,
      responseIsAList,
      api_resource: { 0: parentResource },
    },
  }) => (
    <ResourceActionItem id={uniqueIdentifier}>
      <h2>{name}</h2>

      {WithImplementationSupport(
        SupportBadge,
        `resources.${parentResource.uniqueIdentifier}.endpoints.${uniqueIdentifier}`
      )}

      <Badge
        styling={css`
          font-family: 'Roboto Mono', monospace;
        `}
      >{`${httpMethod} ${uri}`}</Badge>

      {pathParameters ? (
        <ParamFieldTable
          colHeader="Path Parameter"
          fields={pathParameters}
          type="pathParameter"
          action={uniqueIdentifier}
          resource={parentResource.uniqueIdentifier}
        />
      ) : null}

      {bodyParameters ? (
        <ParamFieldTable
          colHeader="Body Parameter"
          fields={bodyParameters}
          type="bodyParameter"
          action={uniqueIdentifier}
          resource={parentResource.uniqueIdentifier}
        />
      ) : null}

      {queryParameters ? (
        <ParamFieldTable
          colHeader="Query Parameter"
          fields={queryParameters}
          type="queryParameter"
          action={uniqueIdentifier}
          resource={parentResource.uniqueIdentifier}
        />
      ) : null}

      {responseData ? (
        <ResponseTable response={{ ...responseData, responseIsAList }} />
      ) : null}
    </ResourceActionItem>
  )
)

export const query = graphql`
  fragment ResourceActionFragment on ContentfulApiAction {
    id
    name
    uniqueIdentifier
    httpMethod
    uri
    api_resource {
      uniqueIdentifier
    }
    bodyParameters {
      ...ParameterFragment
    }
    queryParameters {
      ...ParameterFragment
    }
    pathParameters {
      ...ParameterFragment
    }
  }

  fragment ParameterFragment on ContentfulApiParameter {
    id
    name: parameterKey
    uniqueIdentifier
    api_action {
      uniqueIdentifier
      api_resource {
        uniqueIdentifier
      }
    }
    type: parameterValue {
      id
      name
    }
    explanation: parameterDescription {
      childMarkdownRemark {
        html
      }
    }
    isRequired: required
  }
`
