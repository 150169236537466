import React, { Component } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import { css } from '@emotion/core'

import SearchBox from './search-box'
import SearchResults from './search-results'

const searchGlobals = css`
  & {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    height: auto;
    width: auto;
    z-index: 5;
  }

  .ais-InstantSearch__root {
    display: grid;
    grid-template-columns: 1fr auto;
    border: 1px solid #dee7ee;
    border-radius: 25px;
    background: #fff;
    height: auto;
    width: auto;
  }

  .ais-SearchBox-form,
  .ais-SearchBox,
  .ais-SearchBox-input {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }

  @media print {
    display: none;
  }
`

export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchState: {
        query: '',
      },
    }
  }

  searchState = () => {
    return this.state.searchState
  }

  setSearchState = newSearchState => {
    const oldState = this.state
    this.setState({
      ...oldState,
      searchState: newSearchState,
    })
  }

  resetSearch = () => {
    this.setSearchState({
      query: '',
    })
  }

  render() {
    return (
      <div
        className="search"
        css={css`
          ${searchGlobals}
        `}
      >
        <InstantSearch
          appId="BECXJZDRC2"
          apiKey="5b0db7f0f00d9e1b8cabaca7044ec9b8"
          indexName="prod_apiGuides"
          searchState={this.searchState()}
        >
          <SearchBox
            searchState={this.searchState}
            setSearchState={this.setSearchState}
            resetSearchState={this.resetSearch}
          />
          <SearchResults clickHandler={this.resetSearch} />
        </InstantSearch>
      </div>
    )
  }
}
