import React from 'react'
import { useTable, useFlexLayout } from 'react-table'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import requiredMark from './required.svg'

import WithImplementationSupport from '../shared/with-implementation-support'
import SupportBadge from '../shared/support-badge'

const rowBorder = `1px solid rgba(192, 199, 212, 0.5);`

export const Table = styled.div`
  color: #8a929f;
  margin-bottom: 0.5rem;
  > .table__head {
    margin: 1rem 0;
    text-transform: uppercase;
  }
`

export const TableRow = styled.div`
  border-left: ${rowBorder}
  border-right: ${rowBorder}
  border-bottom: ${rowBorder}
  padding: 1rem;

  &:nth-of-type(1) {
    border-top: ${rowBorder};
    border-radius: 4px 4px 0 0;
  }

  &:nth-last-of-type(1) {
    border-radius: 0 0 4px 4px;
  }

  &:nth-of-type(2):last-of-type {
    border-radius: 4px;
  }

  @media print {
    grid-template-columns: repeat(4, minmax(150px, 1fr));
  }
`

export const TableLegend = styled.div`
  color: #8a929f;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: end;
  & > span {
    display: grid;
    grid-template-columns: 50px 50px;
    justify-items: end;
    align-items: center;
    grid-gap: 2px;
    & > img {
      margin: 0;
    }
  }
`

const requiredParamField = css`
  &::after {
    position: relative;
    content: url(${requiredMark});
    height: 4px;
    width: 4px;
    top: -8px;
    margin: 5px;
  }
`

export default React.memo(
  ({ colHeader, fields, type = 'field', resource, action }) => {
    const tableColumns = React.useMemo(
      () => [
        {
          id: 'paramField',
          Header: colHeader,
          Cell: ({ cell, row }) => {
            return (
              <span css={row.original.isRequired ? requiredParamField : null}>
                {cell.value}
              </span>
            )
          },
          accessor: 'name',
        },
        {
          id: 'type',
          Header: 'Type',
          accessor: 'type.name',
        },
        {
          id: 'support-status',
          Header: 'Support',
          Cell: ({ cell }) => {
            const { value: name } = cell
            const statusPath = action
              ? `resources.${resource}.endpoints.${action}.${type +
                  's'}.${name}`
              : `resources.${resource}.fields.${name}`
            return WithImplementationSupport(SupportBadge, statusPath)
          },
          accessor: 'name',
        },
        {
          id: 'comments',
          Header: 'Comments',
          accessor: 'explanation.childMarkdownRemark.html',
        },
      ],
      [colHeader, action, type, resource]
    )

    const tableData = React.useMemo(() => fields, [fields])

    const tableInstance = useTable(
      {
        columns: tableColumns,
        data: tableData,
        getRowId: (row, relativeIndex) => row.uniqueIdentifier,
      },
      useFlexLayout
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = tableInstance

    return (
      <>
        <Table className="table" {...getTableProps()}>
          <div className="table__head">
            {headerGroups.map(headerGroup => (
              <div
                className="table-head__row"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <div
                    className="table-head__cell"
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="table__body" {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <TableRow className="table-body__row" {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <div
                        className="table-body__cell"
                        {...cell.getCellProps()}
                      >
                        {cell.column.id === 'comments' ? (
                          <span
                            className="cell__content"
                            dangerouslySetInnerHTML={{
                              __html: cell.value || '',
                            }}
                          />
                        ) : (
                          cell.render('Cell')
                        )}
                      </div>
                    )
                  })}
                </TableRow>
              )
            })}
          </div>
        </Table>
        <TableLegend>
          <span>
            <img src={requiredMark} alt="Required Parameter Mark" />
            <small>Required</small>
          </span>
        </TableLegend>
      </>
    )
  }
)

export const query = graphql`
  fragment ResourceFieldFragment on ContentfulApiField {
    id
    name
    uniqueIdentifier
    sampleValue
    type {
      id
      name
      reference: referencedResource {
        name
        uniqueIdentifier
      }
    }
    explanation {
      childMarkdownRemark {
        html
      }
    }
  }
`
